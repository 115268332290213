<template>
  <div>
    <div class="main">
      <div class="header">
        <div>
          <ul>
            <li
              @click="menuTab(index)"
              v-for="(item, index) in tabs"
              :key="index"
              :class="{ active: index == showTab }"
            >
              {{ item.text }}
            </li>
          </ul>
        </div>
      </div>
      <div class="content">
        <div>
          <div v-if="showTab == 0" class="personal-data">
            <div class="background">
              <img :src="iconUrl" alt="">
            </div>
            
            <div class="user-portrait">
              <!-- <el-avatar shape="circle" :size="140" fit="fill" :src="avatarUrl"></el-avatar> -->
              <!-- 背景上传 -->
              <el-upload
                class="avatar-uploader"
                action="no"
                accept=".jpg, .jpeg, .png"
                :http-request="upLoadIcon"
                :show-file-list="false"
              >
                <el-avatar
                  shape="circle"
                  :size="140"
                  fit="fill"
                  :src="iconUrl"
                ></el-avatar>
                <span class="span_">ヘッダー変更 </span>
                <!-- <i class="el-icon-camera avatar-uploader-icon"></i> -->
              </el-upload>
             
            </div>
             <!-- <p class="text">建议上传图片高度 160px，宽度适配</p> -->
            <div class="use-info">
              <el-form
                ref="myForm"
                label-position="left"
                label-width="145px"
                :model="userInfo"
                :rules="rules"
              >
              <!-- 头像上传 -->
              <el-form-item label="アイコン変更">
                <el-upload
                class="avatar-uploader"
                action="no"
                accept=".jpg, .jpeg, .png"
                :http-request="upLoadPic"
                :show-file-list="false"
              >
                <el-avatar
                  shape="square"
                  :size="80"
                  fit="fill"
                  :src="avatarUrl"
                ></el-avatar>
                <!-- <i class="el-icon-camera avatar-uploader-icon"></i> -->
              </el-upload>
                </el-form-item>
                <el-form-item label="ニックネーム" prop="nickname">
                  <el-input
                    style="width: 50%"
                    placeholder="ニックネームを入力して下さい"
                    v-model="userInfo.nickname"
                  ></el-input>
                </el-form-item>
                <el-form-item label="登録属性" prop="loginAttr">
                  <el-radio-group v-model="userInfo.loginAttr" disabled>
                    <el-radio label="1">クリエイター</el-radio>
                    <el-radio label="2">編集者</el-radio>
                  </el-radio-group>
                </el-form-item>
                <template>
                  <el-form-item v-if="userInfo.loginAttr == '1'" label="参加したい工程" prop="hopechecked">
                    <el-checkbox-group v-model="userInfo.hopechecked">
                      <el-checkbox
                        @change="filterLang"
                        v-for="(item, index) in this.userInfo.type"
                        :key="index"
                        :label="item.id"
                        >{{ item.position }}</el-checkbox
                      >
                    </el-checkbox-group>
                  </el-form-item>
                  <el-form-item label="媒体" prop="mediaChecked">
                    <el-checkbox-group v-model="userInfo.mediaChecked">
                      <el-checkbox
                        v-for="item in userInfo.mediaType"
                        :key="item.id"
                        :label="item.id"
                        name="type"
                        >{{ item.media }}</el-checkbox
                      >
                    </el-checkbox-group>
                  </el-form-item>
                </template>
                <el-form-item label="ジャンル" prop="bigChecked">
                  <el-checkbox-group v-model="userInfo.bigChecked">
                    <el-checkbox
                      v-for="item in userInfo.bigType"
                      :key="item.id"
                      :label="item.id"
                      name="type"
                      >{{ item.typeb }}</el-checkbox
                    >
                  </el-checkbox-group>
                </el-form-item>
                <el-form-item label="創作/エッセイ" prop="isCreationType">
                  <el-checkbox-group v-model="userInfo.isCreationType">
                    <el-checkbox label="1" name="type">創作</el-checkbox>
                    <el-checkbox label="2" name="type">エッセイ</el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
                <div class="interested-theme">
                  <div class="title">
                    <h4>興味のある題材</h4>
                    <p @click="addInput">
                      <span>
                        <i class="el-icon el-icon-plus"></i>
                      </span>
                      <span>追加</span>
                    </p>
                  </div>
                  <ul>
                    <li v-for="(item, index) in userInfo.bind" :key="index">
                      <div>
                        <el-input
                          type="text"
                          placeholder="入力して下さい"
                          v-model="userInfo.bind[index].name"
                          maxlength="20"
                          show-word-limit
                        >
                        </el-input>
                      </div>
                      <div @click="deleteInput(index)">削除</div>
                    </li>
                  </ul>
                </div>
                <div class="interested-theme">
                  <div class="title">
                    <h4>自己PR</h4>
                  </div>
                  <div class="ipt-box">
                    <el-input
                      type="textarea"
                      placeholder="入力して下さい"
                      v-model="userInfo.selfIntroduction"
                      maxlength="60"
                      show-word-limit
                      :autosize="{ minRows: 3, maxRows: 4 }"
                    >
                    </el-input>
                  </div>
                </div>
                <div class="interested-theme">
                  <div class="title">
                    <h4>好きな漫画</h4>
                  </div>
                  <div class="ipt-box">
                    <el-input
                      type="textarea"
                      placeholder="入力して下さい"
                      v-model="userInfo.cartoon"
                      maxlength="60"
                      show-word-limit
                      :autosize="{ minRows: 3, maxRows: 4 }"
                    >
                    </el-input>
                  </div>
                </div>
                <div class="interested-theme">
                  <div class="title">
                    <h4>好きな作家</h4>
                  </div>
                  <div class="ipt-box">
                    <el-input
                      type="textarea"
                      placeholder="入力して下さい"
                      v-model="userInfo.favoritePainter"
                      maxlength="60"
                      show-word-limit
                      :autosize="{ minRows: 3, maxRows: 4 }"
                    >
                    </el-input>
                  </div>
                </div>
                <div class="interested-theme">
                  <div class="title">
                    <h4>萌え</h4>
                  </div>
                  <div class="ipt-box">
                    <el-input
                      type="textarea"
                      placeholder="入力して下さい"
                      v-model="userInfo.point"
                      maxlength="60"
                      show-word-limit
                      :autosize="{ minRows: 3, maxRows: 4 }"
                    >
                    </el-input>
                  </div>
                </div>
                <el-form-item
                  v-if="userInfo.loginAttr == '1'"
                  label="作画方法"
                  prop="perSituationMethod"
                  style="margin-top: 20px"
                >
                  <el-radio-group v-model="userInfo.perSituationMethod">
                    <el-radio label="1">アナログ</el-radio>
                    <el-radio label="2">デジタル</el-radio>
                  </el-radio-group>
                </el-form-item>
                <div class="interested-theme" v-if="userInfo.loginAttr == '1'">
                  <div class="title">
                    <h4>受賞歴</h4>
                    <p @click="addRewardInput">
                      <span>
                        <i class="el-icon el-icon-plus"></i>
                      </span>
                      <span>追加</span>
                    </p>
                  </div>
                  <ul>
                    <li
                      v-for="(item, index) in userInfo.getReward"
                      :key="index"
                    >
                      <div>
                        <el-input
                          type="text"
                          placeholder="入力して下さい"
                          v-model="userInfo.getReward[index].name"
                          maxlength="20"
                          show-word-limit
                        >
                        </el-input>
                      </div>
                      <div @click="deleteRewardInput(index)">削除</div>
                    </li>
                  </ul>
                </div>
                <el-form-item
                  label="漫画経験"
                  v-if="userInfo.loginAttr == '1'"
                  prop="experType"
                  style="margin-top: 20px"
                >
                  <el-checkbox-group v-model="userInfo.experType">
                    <el-checkbox label="1" name="type"
                      >アシスタント</el-checkbox
                    >
                    <el-checkbox label="2" name="type">雑誌掲載</el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
                <div class="achievements" v-if="userInfo.loginAttr == '1'">
                  <div class="title">
                    <h4>製作実績</h4>
                    <p @click="addResSel">
                      <span>
                        <i class="el-icon el-icon-plus"></i>
                      </span>
                      <span>追加</span>
                    </p>
                  </div>
                  <div>
                    <ul>
                      <li v-for="(i, index) in userInfo.achievObj" :key="index">
                        <div class="select-box">
                          <div>
                            <el-row :gutter="20">
                              <el-col :span="8">
                                <div>
                                  <el-select
                                    size="medium"
                                    v-model="i.selfProduVal"
                                    placeholder="選択して下さい"
                                  >
                                    <el-option
                                      v-for="item in i.selfProdu"
                                      :key="item.value"
                                      :label="item.label"
                                      :value="item.value"
                                    >
                                    </el-option>
                                  </el-select>
                                </div>
                              </el-col>
                              <el-col :span="8">
                                <div>
                                  <el-select
                                    size="medium"
                                    v-model="i.colorVal"
                                    placeholder="選択して下さい"
                                  >
                                    <el-option
                                      v-for="item in i.color"
                                      :key="item.value"
                                      :label="item.label"
                                      :value="item.value"
                                    >
                                    </el-option>
                                  </el-select>
                                </div>
                              </el-col>
                              <el-col :span="8">
                                <div>
                                  <el-select
                                    size="medium"
                                    v-model="i.isSerialVal"
                                    placeholder="選択して下さい"
                                  >
                                    <el-option
                                      v-for="item in i.isSerial"
                                      :key="item.value"
                                      :label="item.label"
                                      :value="item.value"
                                    >
                                    </el-option>
                                  </el-select>
                                </div>
                              </el-col>
                            </el-row>
                          </div>
                          <div>
                            <el-row :gutter="20">
                              <el-col :span="8">
                                <div>
                                  <el-select
                                    size="medium"
                                    v-model="i.serialSizeVal"
                                    placeholder="選択して下さい"
                                  >
                                    <el-option
                                      v-for="item in i.serialSize"
                                      :key="item.value"
                                      :label="item.label"
                                      :value="item.value"
                                    >
                                    </el-option>
                                  </el-select>
                                </div>
                              </el-col>
                              <el-col :span="8">
                                <div>
                                  <el-input
                                    type="text"
                                    placeholder="作品タイトル"
                                    v-model="i.workName"
                                    maxlength="20"
                                    show-word-limit
                                  >
                                  </el-input>
                                </div>
                              </el-col>
                              <el-col :span="8">
                                <div>
                                  <el-input
                                    type="number"
                                    placeholder="ページ数"
                                    v-model="i.author"
                                  >
                                  </el-input>
                                </div>
                              </el-col>
                            </el-row>
                          </div>
                          <div>
                            <el-input
                              type="text"
                              placeholder="コメント"
                              v-model="i.desc"
                              maxlength="30"
                              show-word-limit
                            >
                            </el-input>
                          </div>
                        </div>
                        <div class="remove" @click="deleteResSel(index)">
                          削除
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="interested-theme" v-if="userInfo.loginAttr == '1'">
                  <div class="title">
                    <h4>ポートフォリオ</h4>
                    <!-- <p @click="addUrlInput">
                      <span>
                        <i class="el-icon el-icon-plus"></i>
                      </span>
                      <span>追加</span>
                    </p> -->
                  </div>
                  <ul>
                    <li>
                      <div>
                        <el-input
                          type="text"
                          placeholder="入力して下さい"
                          v-model="userInfo.introduce"
                          maxlength="30"
                          show-word-limit
                        >
                        </el-input>
                      </div>
                      <!-- <div @click="deleteUrlInput(index)">削除</div> -->
                    </li>
                  </ul>
                </div>
                <el-form-item label="広告マンガ製作依頼" prop="method">
                  <el-radio-group v-model="userInfo.makeAndEdit.make">
                    <el-radio label="1">受ける</el-radio>
                    <el-radio label="2">受けない</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item
                  label="所属編集部"
                  v-if="userInfo.loginAttr == '2'"
                >
                  <el-radio-group v-model="userInfo.perSituationMethod">
                    <el-radio label="1">出版社編集部所属</el-radio>
                    <el-radio label="2">フリーランス</el-radio>
                  </el-radio-group>
                </el-form-item>
                <div class="achievements" v-if="userInfo.loginAttr == '2'">
                  <div class="title">
                    <h4>製作実績</h4>
                    <p @click="addResSel">
                      <span>
                        <i class="el-icon el-icon-plus"></i>
                      </span>
                      <span>追加</span>
                    </p>
                  </div>
                  <div>
                    <ul>
                      <li v-for="(i, index) in userInfo.achievObj" :key="index">
                        <div class="select-box">
                          <div>
                            <el-row :gutter="20">
                              <el-col :span="8">
                                <div>
                                  <el-select
                                    size="medium"
                                    v-model="i.selfProduVal"
                                    placeholder="選択して下さい"
                                  >
                                    <el-option
                                      v-for="item in i.selfProdu"
                                      :key="item.value"
                                      :label="item.label"
                                      :value="item.value"
                                    >
                                    </el-option>
                                  </el-select>
                                </div>
                              </el-col>
                              <el-col :span="8">
                                <div>
                                  <el-select
                                    size="medium"
                                    v-model="i.colorVal"
                                    placeholder="選択して下さい"
                                  >
                                    <el-option
                                      v-for="item in i.color"
                                      :key="item.value"
                                      :label="item.label"
                                      :value="item.value"
                                    >
                                    </el-option>
                                  </el-select>
                                </div>
                              </el-col>
                              <el-col :span="8">
                                <div>
                                  <el-select
                                    size="medium"
                                    v-model="i.isSerialVal"
                                    placeholder="選択して下さい"
                                  >
                                    <el-option
                                      v-for="item in i.isSerial"
                                      :key="item.value"
                                      :label="item.label"
                                      :value="item.value"
                                    >
                                    </el-option>
                                  </el-select>
                                </div>
                              </el-col>
                            </el-row>
                          </div>
                          <div>
                            <el-row :gutter="20">
                              <el-col :span="8">
                                <div>
                                  <el-select
                                    size="medium"
                                    v-model="i.serialSizeVal"
                                    placeholder="選択して下さい"
                                  >
                                    <el-option
                                      v-for="item in i.serialSize"
                                      :key="item.value"
                                      :label="item.label"
                                      :value="item.value"
                                    >
                                    </el-option>
                                  </el-select>
                                </div>
                              </el-col>
                              <el-col :span="8">
                                <div>
                                  <el-input
                                    type="text"
                                    placeholder="作品タイトル"
                                    v-model="i.workName"
                                    maxlength="20"
                                    show-word-limit
                                  >
                                  </el-input>
                                </div>
                              </el-col>
                              <el-col :span="8">
                                <div>
                                   <el-input
                                      type="number"
                                      placeholder="ページ数"
                                      v-model="i.author"
                                    >
                                    </el-input>
                                </div>
                              </el-col>
                            </el-row>
                          </div>
                          <div>
                            <el-input
                              type="text"
                              placeholder="コメント"
                              v-model="i.desc"
                              maxlength="30"
                              show-word-limit
                            >
                            </el-input>
                          </div>
                        </div>
                        <div class="remove" @click="deleteResSel(index)">
                          削除
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <el-form-item label="海外への出版" prop="method">
                  <el-radio-group v-model="userInfo.makeAndEdit.publish">
                    <el-radio label="1">希望する</el-radio>
                    <el-radio label="2">希望しない</el-radio>
                  </el-radio-group>
                </el-form-item>
                <div
                  class="interested-theme"
                  v-if="userInfo.loginAttr == '1' && isShow"
                >
                  <div class="title">
                    <h4>翻訳可能言語</h4>
                    <p @click="addLangSel">
                      <span>
                        <i class="el-icon el-icon-plus"></i>
                      </span>
                      <span>追加</span>
                    </p>
                  </div>
                  <ol>
                    <li v-for="(i, index) in userInfo.language" :key="index">
                      <div>
                        <el-row :gutter="20">
                          <el-col :span="8">
                            <div>
                              <el-select
                                size="medium"
                                v-model="i.fromLanguageVal"
                                placeholder="選択して下さい"
                              >
                                <el-option
                                  v-for="item in i.lang"
                                  :key="item.id"
                                  :label="item.language"
                                  :value="item.id"
                                >
                                </el-option>
                              </el-select>
                            </div>
                          </el-col>
                          <el-col :span="8">
                            <div>
                              <label for="">翻訳</label>
                            </div>
                          </el-col>
                          <el-col :span="8">
                            <div>
                              <el-select
                                size="medium"
                                v-model="i.toLanguageVal"
                                placeholder="選択して下さい"
                              >
                                <el-option
                                  v-for="item in i.lang"
                                  :key="item.id"
                                  :label="item.language"
                                  :value="item.id"
                                >
                                </el-option>
                              </el-select>
                            </div>
                          </el-col>
                        </el-row>
                      </div>
                      <div @click="deleteLang(index)">削除</div>
                    </li>
                  </ol>
                </div>
              </el-form>
              <div class="submit">
                <span @click="openDliog">変更確認</span>
              </div>
              <div style="height: 40px"></div>
            </div>
          </div>
          <!-- 密码修改 -->
          <div v-else>
            <el-form :model="passForm" status-icon :rules="passRules" ref="passForm" label-width="150px" class="demo-ruleForm">
              <el-form-item label="古いパスワード" prop="oldpwd">
                <el-input type="password" v-model="passForm.oldpwd" autocomplete="off"></el-input>
              </el-form-item>
              <el-form-item label="パスワード" prop="newpwd">
                <el-input type="password" v-model="passForm.newpwd" autocomplete="off"></el-input>
              </el-form-item>
              <el-form-item label="パスワード確認" prop="cfmpwd">
                <el-input type="password" v-model="passForm.cfmpwd" autocomplete="off"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" class="c_pass_btn" @click="passSubmitForm('passForm')">変更確認</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>
        <!-- 抽屉 -->
    <el-drawer
      title="登録情報"
      :visible.sync="drawer"
      direction="rtl"
      size="50%"
    >
      <div class="registerInfo">
         <div class="icon" v-if="iconUrl">
            <img :src="iconUrl" alt="">
          </div>
        <el-descriptions :label-style="LS" title="ユーザ情報" :column="1" border>
          <el-descriptions-item label-class-name="my-label" label="アイコン" v-if="userInfo.pic">  <el-avatar :size="80" :src="'/userupload/' + userInfo.pic"></el-avatar> </el-descriptions-item>
          <el-descriptions-item label-class-name="my-label" label="ニックネーム">{{userInfo.nickname}} </el-descriptions-item>
          <el-descriptions-item label-class-name="my-label" label="登録属性" >{{ userInfo.loginAttr == 1 ? 'クリエイター ' : '編集者'}}</el-descriptions-item>
          <el-descriptions-item label-class-name="my-label" v-if="userInfo.loginAttr == 1" label="参加したい工程" style="width:100px">
            <span style="padding: 0 5px" v-for="(item,index) in userInfo.hopechecked">{{filterHopeType(item)}}</span>
          </el-descriptions-item>
          <el-descriptions-item label-class-name="my-label" label="媒体" v-if="userInfo.mediaChecked.length">
            <span style="padding: 0 5px" v-for="(item,index) in userInfo.mediaChecked">{{filterMediaType(item)}}</span>
          </el-descriptions-item>
          <el-descriptions-item label-class-name="my-label" label="ジャンル">
            <span style="padding: 0 5px" v-for="(item,index) in userInfo.bigChecked">{{filterClassAType(item)}}</span>
          </el-descriptions-item>
          <el-descriptions-item label-class-name="my-label" label="創作/エッセイ">
            <template v-if="userInfo.isCreationType.length>1">
              <span style="margin-left:15px">創作</span>
              <span style="margin-left:15px">エッセイ</span>
            </template>
            <template  v-else-if="userInfo.isCreationType.length == 1">
              <span style="margin-left:15px" v-if="userInfo.isCreationType[0] == 1">創作</span>
              <span style="margin-left:15px" v-else>エッセイ</span>
            </template>
          </el-descriptions-item>
          <el-descriptions-item label-class-name="my-label" v-if="userInfo.bind.length && userInfo.bind[0].name" label="興味のある題材">
				    <p v-for="(item,index) in userInfo.bind" :key="index">{{item.name}}</p>
			    </el-descriptions-item>
          
          <!-- <el-descriptions-item v-if="userInfo.quotation" label="タグ">
				    <p>{{userInfo.quotation}}</p>
			    </el-descriptions-item> -->
          <el-descriptions-item label-class-name="my-label" v-if="userInfo.selfIntroduction" label="自己PR">
				    <p>{{userInfo.selfIntroduction}}</p>
			    </el-descriptions-item>
          <el-descriptions-item label-class-name="my-label" v-if="userInfo.cartoon" label="好きな漫画">
				    <p>{{userInfo.cartoon}}</p>
			    </el-descriptions-item>
          <el-descriptions-item label-class-name="my-label" v-if="userInfo.favoritePainter" label="好きな作家">
				    <p>{{userInfo.favoritePainter}}</p>
			    </el-descriptions-item>
          <el-descriptions-item label-class-name="my-label" v-if="userInfo.point" label="萌え">
				    <p>{{userInfo.point}}</p> 
			    </el-descriptions-item>
          <template v-if="userInfo.loginAttr === '1'">
            <el-descriptions-item label-class-name="my-label" v-if="userInfo.perSituationMethod" label="作画方法">
              <p>{{userInfo.perSituationMethod==1 ? "アナログ" : "デジタル"}}</p>
            </el-descriptions-item>
          </template>
          <template v-else>
            <el-descriptions-item label-class-name="my-label" v-if="userInfo.perSituationMethod" label="所属編集部">
              <p>{{userInfo.perSituationMethod == 1 ? "出版社編集部所属" : "フリーランス"}}</p>
            </el-descriptions-item>
          </template>
          <el-descriptions-item label-class-name="my-label" v-if="userInfo.getReward.length && userInfo.getReward[0].name" label="受賞歴">
            <p v-for="(item,index) in userInfo.getReward" :key="index">{{item.name}}</p>
          </el-descriptions-item>

          <el-descriptions-item label-class-name="my-label" v-if="userInfo.experType.length && userInfo.loginAttr == 1" label="漫画経験">
          <template v-if="userInfo.experType.length > 1">
            <span style="margin-left:15px">アシスタント</span>
            <span style="margin-left:15px">雑誌掲載</span>
          </template>
          <template  v-else-if="userInfo.experType.length == 1">
            <span v-if="userInfo.experType[0] == 1">アシスタント</span>
            <span style="margin-left:15px" v-else>雑誌掲載</span>
          </template>
        </el-descriptions-item>

          <el-descriptions-item label-class-name="my-label" v-if="userInfo.achievObj.length" label="製作実績">
				    <div style="padding: 0 5px" v-for="(item,index) in userInfo.achievObj" :key="index">{{filterachievType(item)}}</div>
			    </el-descriptions-item>
          <el-descriptions-item label-class-name="my-label" v-if="userInfo.loginAttr == 1 && userInfo.introduce"  label="ポートフォリオ">
            <p>{{ userInfo.introduce }}</p>
          </el-descriptions-item>
          <el-descriptions-item label-class-name="my-label" v-if="userInfo.makeAndEdit.make" label="広告マンガ製作依頼">
				     <p>{{userInfo.makeAndEdit.make == 1 ? "受ける" : "受けない"}}</p>
			    </el-descriptions-item>
          <el-descriptions-item label-class-name="my-label" v-if="userInfo.makeAndEdit.publish" label="海外への出版">
            <p>{{userInfo.makeAndEdit.publish == 1 ? "希望する" : "希望しない"}}</p>
          </el-descriptions-item>
        </el-descriptions>
      </div>
      <div style="margin-left:15px">
        <el-button @click="drawer = false">取　消</el-button>
        <el-button type="primary" @click="submit">確　定</el-button>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import utils from "@/assets/js/utils";
import service from "@/service";
export default {
  data() {
    // 验证用户昵称
    const checkUsernickname = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("ニックネームを入力して下さい"));
      } else {
        callback();
      }
    };
    const validateOldPass = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('古いパスワードを入力下さい'));
        } else {
          if (this.passForm.cfmpwd !== '') {
            this.$refs.passForm.validateField('cfmpwd');
          }
          callback();
        }
    };
    const validatePass = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('新しいパスワードを入力下さい'));
        } else {
          if (this.passForm.cfmpwd !== '') {
            this.$refs.passForm.validateField('cfmpwd');
          }
          callback();
        }
    };
    const validatePass2 = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('新しいパスワードを再度入力下さい'));
        } else if (value !== this.passForm.newpwd) {
          callback(new Error('パスワードが一致していません!'));
        } else {
          callback();
        }
    };
    return {
      LS: {
      'color': '#000',
      'text-align': 'right',
      'width': '180px',
      'word-break': 'keep-all'
    },
      drawer: false,
      tabs: [
        {
          text: "登録情報",
        },
        {
          text: "パスワード変更",
        },
      ],
      avatarUrl: '',
      iconUrl: '',
      showTab: 0,
      passForm: {
        oldpwd: '',
        newpwd: '',
        cfmpwd: ''
      },
      userInfo: {
        avatarUrl: require("../../../assets/img/code.jpg"),
        pic: "",
        icon: "",
        nickname: "",
        loginAttr: "",
        type: [],
        hopechecked: [],
        mediaType: [],
        mediaChecked: [],
        bigType: [],
        bigChecked: [],

        smallType: [],
        smallChecked: [],
        introduce: '',
        isCreationType: [],

        bind: [],
        quotation: "",
        selfIntroduction: "",
        cartoon: "",
        favoritePainter: "",
        point: "",

        perSituationMethod: "",

        getReward: [],

        experType: [],

        achievObj: [],
        resUrl: [],
        makeAndEdit: {
          make: "",
          edit: "1",
          publish: "",
        },
        language: [],
      },
      isShow: true,
      saveLang: [],
      rules: {
        nickname: [
          { required: true, validator: checkUsernickname, trigger: "blur" },
        ],
        loginAttr: [
          { required: true, message: "请选择活动资源", trigger: "change" },
        ],
        hopechecked: [
          {
            type: "array",
            required: true,
            message: "少なくとも1つ選択して下さい",
            trigger: "change",
          },
        ],
        mediaChecked: [
          {
            type: "array",
            required: true,
            message: "少なくとも1つ選択して下さい",
            trigger: "change",
          },
        ],
        bigChecked: [
          {
            type: "array",
            required: true,
            message: "少なくとも1つ選択して下さい",
            trigger: "change",
          },
        ],
        smallChecked: [
          {
            type: "array",
            required: true,
            message: "少なくとも1つ選択して下さい",
            trigger: "change",
          },
        ],
        isCreationType: [
          {
            type: "array",
            required: true,
            message: "少なくとも1つ選択して下さい",
            trigger: "change",
          },
        ],
        // experType: [
        //   {
        //     type: "array",
        //     required: true,
        //     message: "少なくとも1つ選択して下さい",
        //     trigger: "change",
        //   },
        // ],
        perSituationMethod: [
          { required: true, message: "少なくとも1つ選択して下さい", trigger: "change" },
        ],
      },
      passRules: {
        oldpwd: [
            { required: true, validator: validateOldPass, trigger: 'blur' }
          ],
          newpwd: [
            { required: true, validator: validatePass, trigger: 'blur' }
          ],
          cfmpwd: [
            { required: true, validator: validatePass2, trigger: 'blur' }
          ],
        }
    };
  },
  mounted() {
    this.getLoginInfo();
  },
  methods: {
    // 修改密码
    passSubmitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          service.personal.changePassword(this.passForm).then(res => {
            if(res.status === 200 ) {
              this.$message.success(res.info);
            } else {
              this.$message.error(res.info);
            }
          }).catch()
        } else {
          return false;
        }
      })
    },
    menuTab(index) {
      this.showTab = index;
    },
    filterHopeType(id) {
      let label = "";
      this.userInfo.type.forEach((item) => {
        if (id == item.id) {
          label = item.position;
        }
      });
      return label;
    },
    // 制作实绩
    filterachievType(item) {
      let achievData = []
      const { workName,author,desc } = item
      for(const i in item) {
        if(i == 'selfProduVal') {
          item.selfProdu.forEach(it => {
            if(item[i] === it.value) {
              achievData.push(it.label)
            }
          })
        }
        if(i == 'colorVal') {
          item.color.forEach(it => {
            if(item[i] === it.value) {
              achievData.push(it.label)
            }
          })
        }
        if(i == 'isSerialVal') {
          item.isSerial.forEach(it => {
            if(item[i] === it.value) {
              achievData.push(it.label)
            }
          })
        }
        if(i == 'serialSizeVal') {
          item.serialSize.forEach(it => {
            if(item[i] === it.value) {
              achievData.push(it.label)
            }
          })
        }
      }
      achievData.push(workName,author,desc)
      return achievData.join(' ');
    },
    filterMediaType(id) {
      let label = "";
      this.userInfo.mediaType.forEach((item) => {
        if (id == item.id) label = item.media;
      });
      return label;
    },
    filterClassAType(id) {
      let label = "";
      this.userInfo.bigType.forEach((item) => {
        if (id == item.id) label = item.typeb;
      });
      return label;
    },
    openDliog() {
      this.drawer = true
      // console.log(this.userInfo.loginAttr,"00000")
      // if(this.userInfo.loginAttr == '2') {
      //   this.userInfo.perSituationMethod = this.userInfo.makeAndEdit.edit
      // }
    },
    //获取注册数据
    getLoginInfo() {
      service.personal
        .getLoginInfo()
        .then((res) => {
          if (res.status === 200) {
            let data = res.data;
            this.userInfo.type = data.positions;
            this.userInfo.mediaType = data.medias;
            this.userInfo.bigType = data.typebigs;
            this.userInfo.smallType = data.typesmalls;
            this.saveLang = data.language;
            let checkedData = data.data;
            this.userInfo.pic = checkedData.pic;
            this.userInfo.icon = checkedData.icon;
            this.avatarUrl = '/userupload/'+checkedData.pic;
            this.iconUrl =  '/userupload/'+checkedData.icon;
            this.userInfo.nickname = checkedData.nickname;
            this.userInfo.loginAttr = checkedData.loginattr.toString();
            this.userInfo.hopechecked = checkedData.positions.split(",").map(Number);
            this.userInfo.mediaChecked = checkedData.media.split(",").map(Number);
            this.userInfo.bigChecked = checkedData.typebs.split(",").map(Number);
            this.userInfo.isCreationType = checkedData.creativecap.split(",")
            this.userInfo.introduce = checkedData.introduce
            this.userInfo.bind = checkedData.theme ? utils.resolvingData("input",checkedData.theme.split("#")): []
            this.userInfo.quotation = checkedData.quotation;
            this.userInfo.selfIntroduction = checkedData.introduction;
            this.userInfo.cartoon = checkedData.cartoon;
            this.userInfo.favoritePainter = checkedData.cartooner;
            this.userInfo.point = checkedData.cute;
            this.userInfo.perSituationMethod = checkedData.drawmethod.toString();
            
            if (checkedData.reward) {
              this.userInfo.getReward = utils.resolvingData("input",checkedData.reward.split("#"));
            }

            this.userInfo.experType = [];
            let arr = [];
            if (checkedData.achievements) {
              arr = checkedData.achievements.split("#");
			        this.userInfo.achievObj = utils.resolvingData("select", arr);
            }
            this.userInfo.experType = checkedData.experience ? checkedData.experience.split(",") : [];
            
            if (checkedData.introduce) {
              this.userInfo.resUrl = utils.resolvingData( "input",checkedData.introduce.split("#"));
            }
            if(checkedData.publish) {
              this.userInfo.makeAndEdit.publish = checkedData.publish.toString();
            }
            if(checkedData.adscartoon) {
              this.userInfo.makeAndEdit.make = checkedData.adscartoon.toString();
            }
            
            this.userInfo.language = [];
            let langarr = []
            if(checkedData.translate) {
              langarr = checkedData.translate.split("#");
            }
            let arr1 = [];
            for (let i = 0; i < langarr.length; i++) {
              arr1 = langarr[i].split(";");
              this.userInfo.language.push({
                lang: this.saveLang,
                fromLanguageVal: arr1[0] * 1,
                toLanguageVal: arr1[1] * 1,
              });
            }
            this.userInfo.hopechecked.indexOf(14) > -1
              ? (this.isShow = true)
              : (this.isShow = false);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    upLoadPic(e) {
      if (e.file) {
        const file = e.file;
        let formData = new FormData();
        formData.append("file", file);
        service.home
          .userUpload(formData)
          .then((res) => {
            this.avatarUrl=`/userupload/${ res.data.filename }`
            this.userInfo.pic = res.data.filename;
            this.$message.success("アイコンがアップロードされました");
          })
          .catch(() => {
            this.$message.error("アイコンのアップロードに失敗しました");
          });
      }
    },
    upLoadIcon(e) {
      if (e.file) {
        const file = e.file;
        let formData = new FormData();
        formData.append("file", file);
        service.home
          .userUpload(formData)
          .then((res) => {
            if(res.status === 200) {
              this.iconUrl=`/userupload/${ res.data.filename }`
              this.userInfo.icon = res.data.filename;
              this.$message.success("アイコンがアップロードされました");
            } else {
              this.$message.success(res.info);
            }
          })
          .catch(() => {
            this.$message.error("アイコンのアップロードに失敗しました");
          });
      }
    },
    filterLang() {
      this.userInfo.hopechecked.indexOf(14) > -1
        ? (this.isShow = true)
        : (this.isShow = false);
    },
    submit() {
      let them = "";
      let getReward = "";
      let resUrl = "";
      if (this.userInfo.bind.length) {
        them = this.userInfo.bind
          .map((item) => {
            return item.name;
          })
          .join("#");
      }
      if (this.userInfo.getReward.length) {
        getReward = this.userInfo.getReward
          .map((item) => {
            return item.name;
          })
          .join("#");
      }
      if (this.userInfo.resUrl.length) {
        resUrl = this.userInfo.resUrl
          .map((item) => {
            return item.name;
          })
          .join("#");
      }

      let lang = [];
      if (this.userInfo.language.length) {
        for (let i = 0; i < this.userInfo.language.length; i++) {
          lang.push(
            this.userInfo.language[i].fromLanguageVal +
              ";" +
              this.userInfo.language[i].toLanguageVal
          );
        }
      }
      let makeRes = [];
      if (this.userInfo.achievObj.length) {
        for (let i = 0; i < this.userInfo.achievObj.length; i++) {
          makeRes.push(
            this.userInfo.achievObj[i].selfProduVal +
              ";" +
              this.userInfo.achievObj[i].colorVal +
              ";" +
              this.userInfo.achievObj[i].isSerialVal +
              ";" +
              this.userInfo.achievObj[i].serialSizeVal +
              ";" +
              this.userInfo.achievObj[i].workName +
              ";" +
              this.userInfo.achievObj[i].author +
              ";" +
              this.userInfo.achievObj[i].desc
          );
        }
      }
      this.$refs.myForm.validate((valid) => {
        if (valid) {
          let params = {
            icon: this.userInfo.icon, //图标
            pic: this.userInfo.pic, //头像
            loginattr: this.userInfo.loginAttr,
            positions: this.userInfo.hopechecked.join(","),
            media: this.userInfo.mediaChecked.join(","),
            typebs: this.userInfo.bigChecked.join(","), //大分类
            types: this.userInfo.smallChecked.join(","), //小分类
            creativecap: this.userInfo.isCreationType.join(","), // 创作 or 随想
            experience: this.userInfo.experType.join(",") || "", // 作画经验
            adscartoon: this.userInfo.makeAndEdit.make, // 制作依赖
            nickname: this.userInfo.nickname, //昵称
            theme: them, //感兴趣的题材
            quotation: this.userInfo.quotation, //引语
            introduction: this.userInfo.selfIntroduction, //自我介绍
            cartoon: this.userInfo.cartoon, //喜欢的漫画
            cartooner: this.userInfo.favoritePainter, //喜欢的漫画家
            cute: this.userInfo.point, //萌点
            drawmethod: this.userInfo.perSituationMethod, //作画方法
            reward: getReward, //得到的奖励
            achievements: makeRes.join("#") || '', //制作成果
            introduce: this.userInfo.introduce, //外部URL
            publish: this.userInfo.makeAndEdit.publish, //海外出版
            translate: lang.join("#"), //翻译
          };
          service.personal.userInfoEdit(params).then((res) => {
            this.$message.success("登録情報を変更しました");
            this.getLoginInfo();
            this.drawer = false
            window.location.reload() 
          });
        } else {
          // alert("22")
        }
      });
    },
    addInput() {
      utils.addItem("input", this.userInfo.bind);
    },
    deleteInput(index) {
      utils.deleteItem(this.userInfo.bind, index);
    },
    addRewardInput() {
      utils.addItem("input", this.userInfo.getReward);
    },
    deleteRewardInput(index) {
      utils.deleteItem(this.userInfo.getReward, index);
    },
    addResSel() {
      utils.addItem("select", this.userInfo.achievObj);
    },
    deleteResSel(index) {
      utils.deleteItem(this.userInfo.achievObj, index);
    },
    addUrlInput() {
      utils.addItem("input", this.userInfo.resUrl);
    },
    deleteUrlInput(index) {
      utils.deleteItem(this.userInfo.resUrl, index);
    },
    addLangSel() {
      this.userInfo.language.push({
        lang: this.saveLang,
        fromLanguageVal: "",
        toLanguageVal: "",
      });
    },
    deleteLang(index) {
      utils.deleteItem(this.userInfo.language, index);
    },
  },
};
</script>
<style>
  .my-label {
    background: #ddd !important;
  }
</style>
<style lang="scss" scoped>
.main {
  width: 100%;
  .header {
    width: 100%;
    border-bottom: 1px solid #ddd;
    position: sticky;
    top: 0px;
    // background-color: #fff;
    z-index: 888;
    > div {
      ul {
        display: flex;
        padding: 0 40px;
        li {
          height: 67px;
          line-height: 67px;
          cursor: pointer;
          margin-right: 30px;
        }
        li.active {
          border-bottom: 3px solid #333;
        }
      }
    }
  }
  .content {
    margin-top: 40px;
    > div {
      padding: 0 40px;
      .personal-data {
        
        .background {
          width: 100%;
          height: 160px;
           text-align: center;
          img {
            max-height: 100%;
            max-width: 100%;
          }
          
        }
        // .text {
        //     text-align: center;
        //     position: relative;
        //     top: -75px;
        //     color: #f03747;
        //   }
        .user-portrait {
          
          text-align: center;
          position: relative;
          display: flex;
          justify-content: center;
          top: -80px;
          ::v-deep {
            .avatar-uploader {
              .el-upload {
                span.span_ {
                  height: 140px;
                  line-height: 140px;
                  width: 140px;
                  border-radius: 100%;
                  background-color: rgba(0, 0, 0, 0.5);
                  position: absolute;
                  color: #fff;
                  cursor: pointer;
                  font-size: 16px;
                  left: 50%;
                  transform: translate(-50%);
                }
              }
            }
          }
        }
        .use-info {
          // margin-top: 90px;
          ::v-deep .el-checkbox {
            width: 160px;
            margin-right: 60px;
          }
          .classifi {
            max-height: 180px;
            overflow-y: scroll;
          }
          .classifi::-webkit-scrollbar {
            width: 5px;
          }
          .classifi::-webkit-scrollbar-track {
            background-color: #f4f8fb;
            border-radius: 10px;
          }
          .classifi::-webkit-scrollbar-thumb {
            background-color: #ccc;
            border-radius: 10px;
          }
          .interested-theme {
            margin-top: 20px;
            .title {
              display: flex;
              align-items: center;
              h4 {
                height: 40px;
                line-height: 40px;
                font-size: 14px;
                color: #333;
                font-weight: 600;
                letter-spacing: 2px;
              }
              p {
                margin-left: 20px;
                cursor: pointer;
                span:first-child {
                  display: inline-block;
                  width: 15px;
                  height: 15px;
                  border-radius: 2px;
                  background-color: #0051d9;
                  line-height: 15px;
                  text-align: center;
                  margin-right: 5px;
                  i {
                    color: #fff;
                    font-size: 12px;
                  }
                }
                span:last-child {
                  color: #0051d9;
                }
              }
            }
            ul {
              li {
                display: flex;
                align-items: center;
                margin: 10px 0;
                width: 100%;
                div:first-child {
                  flex: 1;
                }
                > div:last-child {
                  width: 100px;
                  text-align: center;
                  cursor: pointer;
                  color: #f03747;
                  // visibility: hidden;
                }
              }
              li:hover {
                > div:last-child {
                  visibility: visible;
                }
              }
            }
            .ipt-box {
              width: calc(100% - 100px);
            }
            ol {
              li {
                margin-top: 20px;
                display: flex;
                align-items: center;
                > div:first-child {
                  text-align: center;
                  width: calc(100% - 100px);
                  ::v-deep .el-select {
                    width: 100%;
                  }
                  label {
                    // color: #606266;
                    line-height: 36px;
                  }
                }
                > div:last-child {
                  width: 100px;
                  text-align: center;
                  cursor: pointer;
                  color: #f03747;
                  visibility: hidden;
                }
              }
              li:hover {
                > div:last-child {
                  visibility: visible;
                }
              }
            }
          }
          .achievements {
            .title {
              display: flex;
              align-items: center;
              p {
                margin-left: 20px;
                cursor: pointer;
                span:first-child {
                  display: inline-block;
                  width: 15px;
                  height: 15px;
                  border-radius: 2px;
                  background-color: #0051d9;
                  line-height: 15px;
                  text-align: center;
                  margin-right: 5px;
                  i {
                    color: #fff;
                    font-size: 12px;
                  }
                }
                span:last-child {
                  color: #0051d9;
                }
              }
            }
            > div {
              ul {
                width: 100%;
                li {
                  width: 100%;
                  display: flex;
                  align-items: center;
                  padding: 10px;
                  border: 1px solid transparent;
                  .select-box {
                    width: calc(100% - 100px);
                    ::v-deep .el-select {
                      width: 100%;
                    }
                    > div {
                      margin-top: 10px;
                    }
                  }
                  .remove {
                    width: 100px;
                    text-align: center;
                    cursor: pointer;
                    color: #f03747;
                    visibility: hidden;
                  }
                }
                li:hover {
                  border: 1px solid #bfbfbf;
                  .remove {
                    visibility: visible;
                  }
                }
              }
            }
          }
          .submit {
            display: flex;
            justify-content: center;
            padding-top: 40px;
            padding-bottom: 40px;
            span {
              display: block;
              width: 200px;
              height: 50px;
              line-height: 50px;
              border-radius: 8px;
              background: #f03747;
              color: #fff;
              text-align: center;
              cursor: pointer;
            }
          }
        }
      }
    }
    .c_pass_btn {
      display: block;
      width: 200px;
      height: 50px;
      border-radius: 8px;
      margin: 0 auto;
      color: #fff;
      text-align: center;
      background: #f03747;
      border-color: #f03747;
      cursor: pointer;
    }
  }
}
::v-deep.registerInfo {
  padding: 20px;
    .icon {
    height: 160px;
    text-align: center;
    img {
      max-height: 100%;
    }
  }
  ul {
    width: 100%;
    li {
      span {
        margin-left: 20px;
        font-size: 16px;
      }
      height: 32px;
      h4 {
        font-size: 18px;
      }
      div {
        padding-left: 20px;
      }
    }
  }
}
.el-descriptions-item__label.is-bordered-label {
  background: #ddd !important
}
</style>
